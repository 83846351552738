// Assets
import imgLogoS from "../assets/images/logo/logo_ramiware_100x100.png"
import imgLogoL from "../assets/images/logo/logo_ramiware_820x820.png"
import imgRamiwareTxt from "../assets/images/logo/logo-text-1500x200.png"

/***********************************************************************************************************
 * COMPONENT START
 ***********************************************************************************************************/
export function useBusiness() {

  const card = {

    BUS_WEBSITE: 'https://www.ramiware.com',
    BUS_NAME: 'RAMIWARE',
    BUS_EMAIL: 'ramisorikh@ramiware.com',
    LINK_LINKEDIN: "https://www.linkedin.com/in/ramiware/",
    LINK_GITHUB: "https://github.com/ramiware",
    LINK_MAILTO: 'mailto:ramisorikh@ramiware.com?subject=Found Your Ramiware Portfolio!'

  }


  return { imgLogoS, imgLogoL, imgRamiwareTxt, card }
};