import { Box, Center, Flex, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";

// Hooks
import { useTheme } from "../hooks/useTheme";

// Components
import Computer from "../components/Computer";

/***********************************************************************************************************
 * COMPONENT START
 ***********************************************************************************************************/
const Hero = () => {

  const HERO_TITLE_LN1 = "WELCOME TO";
  const HERO_TITLE_LN2 = "RAMIWARE";
  const HERO_BODY_TXT = "Ramiware is simply a representation of what I create";

  const { fonts, sizes } = useTheme();

  /***********************************************************************************************************
   * UI
   ***********************************************************************************************************/
  return (
    <Box as="main" mt={[sizes.HERO_MT_S, sizes.HERO_MT_S, sizes.HERO_MT_L, sizes.HERO_MT_L]}
      pt={[sizes.SECTION_PTB_S, sizes.SECTION_PTB_S, sizes.SECTION_PTB_L, sizes.SECTION_PTB_L]}
      pb={[sizes.SECTION_PTB_S, sizes.SECTION_PTB_S, sizes.SECTION_PTB_L, sizes.SECTION_PTB_L]}
      // bgPos={'center'} bgSize={"cover"} bgRepeat={"no-repeat"}
      // bgColor='rgba(0, 0, 0, 0.25)'
      color='white'
      width='100%'
      height='innerHeight'
    >

      <Center
        backgroundColor={{ base: 'none', md: 'rgba(0, 0, 0, 0.5)' }}
        backdropFilter={{ base: 'none', md: 'saturate(5%) blur(5px)' }}
      >
        <Flex
          gap={['20px', '20px', '20px', '20px']}
          width={[sizes.SECTION_W_S, sizes.SECTION_W_M, sizes.SECTION_W_L, sizes.SECTION_W_XL]}
          justifyContent={'center'} flexWrap={'wrap'}>


          {/* WELCOME TEXT */}
          <Box mt={{ md: '30px' }}>
            <Center>
              <VStack spacing='0px'>

                {/* TEXT LINE 1 */}
                <Box lineHeight={['50px', '50px', '145px', '145px']}
                  width={[sizes.TERMINAL_W_S, sizes.TERMINAL_W_M, sizes.TERMINAL_W_L, sizes.TERMINAL_W_XL]}
                  fontFamily={'heading'} fontWeight='bold'
                  fontSize={[fonts.HERO_H2_S, fonts.HERO_H2_M, fonts.HERO_H2_L, fonts.HERO_H2_XL]}
                >
                  <motion.div
                    initial={{ opacity: 0, x: 0, scale: 0.8 }}
                    animate={{ opacity: 1, x: 0, scale: 1, }}
                    transition={{ delay: 2, duration: 3, }}
                  >
                    <Text>{HERO_TITLE_LN1}</Text>
                  </motion.div>
                </Box>

                {/* TEXT LINE 2 */}
                <Box lineHeight={['80px', '80px', '145px', '145px']} letterSpacing={{ base: 5.0, md: 1.0 }}
                  width={[sizes.TERMINAL_W_S, sizes.TERMINAL_W_M, sizes.TERMINAL_W_L, sizes.TERMINAL_W_XL]}
                  fontFamily={'heading'} fontWeight='bold'
                  fontSize={[fonts.HERO_H1_S, fonts.HERO_H1_M, fonts.HERO_H1_L, fonts.HERO_H1_XL]}
                >
                  <motion.div
                    initial={{ opacity: 0, x: 0, scale: 0.8 }}
                    animate={{ opacity: 1, x: 0, scale: 1, }}
                    transition={{ delay: 3, duration: 3, }}
                  >
                    <Text bgGradient='linear(to-r, #39B54A, green)' bgClip='text'>
                      {HERO_TITLE_LN2}
                    </Text>
                  </motion.div>
                </Box>

                {/* TEXT LINE 3 */}
                <Box
                  width={[sizes.TERMINAL_W_S, sizes.TERMINAL_W_M, sizes.TERMINAL_W_L, sizes.TERMINAL_W_XL]}
                  fontFamily={'cmd'} fontWeight='bold'
                  fontSize={[fonts.HERO_H3_S, fonts.HERO_H3_M, fonts.HERO_H3_L, fonts.HERO_H3_XL]}
                >
                  <motion.div
                    initial={{ opacity: 0, x: 0, scale: 0.8 }}
                    animate={{ opacity: 1, x: 0, scale: 1, }}
                    transition={{ delay: 4, duration: 3, }}
                  >
                    <Text>{HERO_BODY_TXT}</Text>
                  </motion.div>
                </Box>


                {/* MOBILE ONLY: GRAPHIC SECTION */}
                <Box mt='20px' display={{ base: 'block', md: 'none' }}>
                  <Center>
                    <Computer />
                  </Center>
                </Box>

              </VStack>
            </Center>
          </Box>


          {/* DESKTOP ONLY: GRAPHIC SECTION */}
          <Box display={{ base: 'none', md: 'block' }}>
            <Center>
              <Computer />
            </Center>
          </Box>

        </Flex>

      </Center>

    </Box>
  );
};
export default Hero;