import {
  ChakraProvider,
  Box,
} from "@chakra-ui/react"

import Theme from "./components/Theme"
import Fonts from "./components/Fonts"
import Home from "./pages/Home"


/***********************************************************************************************************
 * COMPONENT START
 ***********************************************************************************************************/
export const App = () => (
  <ChakraProvider theme={Theme}>
    <Fonts />
    <Box textAlign="center">

      <Home></Home>

    </Box>
  </ChakraProvider>
)
