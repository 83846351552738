import { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";

/***********************************************************************************************************
 * COMPONENT START
 ***********************************************************************************************************/
function Counter() {

  const [count, setCount] = useState(0);

  useEffect(() => {

    const storedCount = localStorage.getItem("pageVisits");
    const initialCount = Number(storedCount) || 0;
    setCount(initialCount + 1);
    localStorage.setItem("pageVisits", (initialCount + 1).toString());
    console.log("SC, IC, C", storedCount, initialCount, count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***********************************************************************************************************
  * UI
  ***********************************************************************************************************/
  return (
    <Box>

    </Box>
  );

}

export default Counter;