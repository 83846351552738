import { Box, Center, HStack, Text, VStack, Image, Spacer, Flex } from "@chakra-ui/react";
import { VscTerminalCmd, VscChromeMinimize, VscChromeMaximize, VscChromeClose, } from "react-icons/vsc";
import { ReactTyped } from "react-typed"

// Hooks
import { useTheme } from "../hooks/useTheme";


/***********************************************************************************************************
 * COMPONENT START
 ***********************************************************************************************************/
const Feature = ({ projectName, projectType, description, techUsed, textColor, themeColor, image, linkText1, linkURL1, linkText2, linkURL2, setImageFirst }: any) => {

  const { fonts, sizes } = useTheme();


  /// ----------------------------------------------------------------
  /// Returns the image component
  /// ----------------------------------------------------------------
  function GetImage() {
    return (

      <Box boxShadow='dark-lg' rounded={sizes.SECTION_BOX_RADIUS} boxSize={[sizes.PHOTO_BOXSIZE_S, sizes.PHOTO_BOXSIZE_M, sizes.PHOTO_BOXSIZE_L, sizes.PHOTO_BOXSIZE_XL]}>
        <Center>
          <Image src={image}
            boxSize={[sizes.PHOTO_BOXSIZE_S, sizes.PHOTO_BOXSIZE_M, sizes.PHOTO_BOXSIZE_L, sizes.PHOTO_BOXSIZE_XL]}
            borderWidth={'2px'}
            borderColor={themeColor}
            borderRadius={sizes.SECTION_BOX_RADIUS}
            borderStyle='solid'
          ></Image>
        </Center>
      </Box>
    )
  }

  /// ----------------------------------------------------------------
  /// Returns the terminal UI frame only
  /// ----------------------------------------------------------------
  function GetTerminal() {
    return (

      <Box boxShadow='dark-lg' rounded={sizes.SECTION_BOX_RADIUS}>
        <Center>
          <VStack spacing='0px'>

            {/* TOOLBAR */}
            <Box height='40px'
              width={[sizes.TERMINAL_W_S, sizes.TERMINAL_W_M, sizes.TERMINAL_W_L, sizes.TERMINAL_W_XL]}
              bgColor={themeColor}
              borderColor={themeColor}
              borderWidth={'2px'}
              borderStyle='solid'
              borderTopRadius={sizes.SECTION_BOX_RADIUS}
              fontFamily={'cmdBold'} fontStyle={'bold'}
              fontSize={[fonts.T1_SIZE_S, fonts.T1_SIZE_S, fonts.T1_SIZE_L, fonts.T1_SIZE_L]}
            >
              <HStack height='100%' pl='10px' pr='10px'>
                <VscTerminalCmd />
                <Text pt={['5px', '5px', '2px', '2px']}>Project: {projectName.substring(0, 17)}{projectName.length > 17 ? '...' : ''}</Text>
                <Spacer />
                <VscChromeMinimize />
                <VscChromeMaximize />
                <VscChromeClose />
              </HStack>
            </Box>

            {/* CONTENT */}
            <Box height={[sizes.TERMINAL_H_S, sizes.TERMINAL_H_M, sizes.TERMINAL_H_L, sizes.TERMINAL_H_XL]}
              width={[sizes.TERMINAL_W_S, sizes.TERMINAL_W_M, sizes.TERMINAL_W_L, sizes.TERMINAL_W_XL]}
              p={['10px', '10px', '20px', '20px']}
              bgColor='black'
              color={textColor}
              borderColor={themeColor}
              borderWidth={'2px'}
              borderStyle='solid'
              borderTopWidth={'0px'}
              borderBottomRadius={sizes.SECTION_BOX_RADIUS}
              fontFamily={'cmd'} fontStyle={'normal'}
              fontSize={[fonts.T2_SIZE_S, fonts.T2_SIZE_S, fonts.T2_SIZE_L, fonts.T2_SIZE_L]}
              textAlign='left'
            >

              {GetProjectContent(techUsed)}


            </Box>

          </VStack>
        </Center>
      </Box>
    )
  }

  /// ----------------------------------------------------------------
  /// Returns the project details / terminal contents
  /// ----------------------------------------------------------------
  function GetProjectContent(technology: string) {

    const techList = technology.split(",");

    return (
      <VStack spacing='0px' align={'left'}>
        <Flex gap={'5px'} pb='20px' flexWrap='wrap'>
          {techList.map((tech, index) => (
            <Text key={index} bgColor={themeColor} color='white' borderRadius={'8px'} pl='15px' pr='15px'>{tech}</Text>
          ))}
        </Flex>

        <ReactTyped
          startWhenVisible
          strings={GetDescriptionAsArray()}
          typeSpeed={10}
          backSpeed={100}
        // loop
        />
      </VStack>

    )
  }

  /// ----------------------------------------------------------------
  /// Returns the text used in the terminal
  /// ----------------------------------------------------------------
  function GetDescriptionAsArray() {
    let descriptionAsString =
      "<b>Project Name:</b> <font color='white'>" + projectName + "</font><br><br>" +
      "<b>Project Type:</b> <font color='white'>" + projectType + "</font><br><br>" +
      "<b>Description:</b> <br><font color='white'>" + description + "</font><br><br><br>";

    if (linkText1.length > 0 && linkURL1.length > 0)
      descriptionAsString += "> <a href='" + linkURL1 + "' target='_blank'>" + linkText1 + "</a>";
    else if (linkText1.length > 0 && linkURL1.length === 0)
      descriptionAsString += linkText1 + "";

    if (linkText2.length > 0 && linkURL2.length > 0)
      descriptionAsString += "<br>> <a href='" + linkURL2 + "' target='_blank'>" + linkText2 + "</a>";
    else if (linkText2.length > 0 && linkURL2.length === 0)
      descriptionAsString += "<br>" + linkText2 + "";



    const descriptionAsArray = [descriptionAsString];
    return descriptionAsArray;
  }

  /***********************************************************************************************************
   * UI
   ***********************************************************************************************************/
  return (
    <Box>

      {/* SECTION BODY */}
      <Center>
        <Flex gap={['20px', '20px', '20px', '20px']}
          width={[sizes.SECTION_W_S, sizes.SECTION_W_M, sizes.SECTION_W_L, sizes.SECTION_W_XL]}
          justifyContent={'center'} flexWrap={'wrap'}>

          {/* DESKTOP */}
          <Box display={{ base: 'none', md: 'contents' }}>
            {/* TERMINAL */}
            {setImageFirst ? GetImage() : GetTerminal()}

            {/* PROFILE PIC */}
            {setImageFirst ? GetTerminal() : GetImage()}
          </Box>

          {/* MOBILE */}
          <Box display={{ base: 'contents', md: 'none' }}>
            {GetImage()}
            {GetTerminal()}
          </Box>

        </Flex>
      </Center>

    </Box>
  );
};
export default Feature;