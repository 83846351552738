import { Center, Text, VStack } from "@chakra-ui/react";
import { ReactTyped } from "react-typed"

// Hooks
import { useTheme } from "../hooks/useTheme";


/***********************************************************************************************************
 * COMPONENT START
 ***********************************************************************************************************/
const SectionHeader = ({ title, description }: any) => {

  const { fonts, sizes } = useTheme();

  const SECTION_TITLE_LIST: string[] = [title];
  const SECTION_DESCRIPTION_LIST: string[] = [description];

  /***********************************************************************************************************
   * UI
   ***********************************************************************************************************/
  return (
    <Center>
      <VStack pb='50px'
        width={[sizes.SECHDR_W_S, sizes.SECHDR_W_M, sizes.SECHDR_W_L, sizes.SECHDR_W_XL]}>

        {/* TITLE */}
        <Text fontSize={[fonts.H1_SIZE_S, fonts.H1_SIZE_S, fonts.H1_SIZE_L, fonts.H1_SIZE_L]}
          fontFamily={'heading'} fontWeight={'bold'}>
          <ReactTyped
            startWhenVisible
            strings={SECTION_TITLE_LIST}
            typeSpeed={250}
            backSpeed={100}
          // loop
          />
        </Text>

        {/* DESCRIPTION */}
        <Text pl='15px' pr='15px'
          fontSize={[fonts.H2_SIZE_S, fonts.H2_SIZE_S, fonts.H2_SIZE_L, fonts.H2_SIZE_L]}
          fontFamily={'cmd'}>
          <ReactTyped
            startWhenVisible
            strings={SECTION_DESCRIPTION_LIST}
            typeSpeed={10}
            backSpeed={100}
          // loop
          />
        </Text>
      </VStack>
    </Center>


  );

};

export default SectionHeader;