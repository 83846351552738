// Assets
import imgProject1 from "../assets/images/projects/kardiakrush1.png"
import imgProject2 from "../assets/images/projects/ramiwaretoken1.png"
import imgProject3 from "../assets/images/projects/todayssales1.png"
import imgProject4 from "../assets/images/projects/showbatteryperentageintaskbar1.png"
import imgProject5 from "../assets/images/projects/commandcentral1.png"
import imgProject6 from "../assets/images/projects/fileextensionrenametool1.png"
import imgProject7 from "../assets/images/projects/birthdayreminder1.png"
import imgProject8 from "../assets/images/projects/windowsuptimealarm1.png"
import imgProject9 from "../assets/images/projects/ahadithorganizer1.png"
import imgProject10 from "../assets/images/projects/outlookdesktopmailalerts1.png"


const PROJECTS_TITLE = "Projects";
const PROJECTS_DESCRIPTION =
  "While I'm fluent in many languages, my favourites at the moment are C#, Solidity, React, MSSQL, Flutter via Dart, Emoji-talk, and Arabic." +
  "</br></br>Here are some highlights of some of the projects I have worked on.";

type Project = {
  id: string,
  name: string,
  type: string,
  description: string,
  tech: string,
  textColor: string,
  themeColor: string,
  thumb: string,
  linkText1: string,
  linkURL1: string,
  linkText2: string,
  linkURL2: string
}

const project1: Project = {
  id: '1',
  name: 'Kardia Krush',
  type: 'Decentralized App (DAP)',
  description:
    'Kardia Krush is a Play-to-Earn gem-matching game built on the KardiaChain blockchain.' +
    '</br>The game objective is to create as many matches of 3, 4, or 5 Kardia Gems before time runs out.' +
    '</br>If you like Candy Crush, you will love Kardia Krush!' +
    '</br>It is hosted in Kardia Wallet and rewards top players with $KAI tokens every week.',
  tech: 'solidity, react, express, node, mongodb, kardia-js-sdk',
  textColor: '#917BED',
  themeColor: '#8F00C0',
  thumb: imgProject1,
  linkText1: 'Play Now',
  linkURL1: 'https://kardiakrush.com',
  linkText2: '',
  linkURL2: ''

}

const project2: Project = {
  id: '2',
  name: 'Ramiware Token',
  type: 'Smart Contract',
  description:
    'Ramiware Token is a cryptocurrency on the KardiaChain Testnet with a total supply of 47 million.' +
    '</br>The token symbol is RAMI and the token type is KRC20.',
  tech: 'solidity',
  textColor: '#39B54A',
  themeColor: '#057C25',
  thumb: imgProject2,
  linkText1: 'View Smart Contract [0x9942e83AEdf237896EB06bBE0AdDE6c221DEAF88]',
  linkURL1: 'https://explorer-dev.kardiachain.io/token/0x9942e83AEdf237896EB06bBE0AdDE6c221DEAF88',
  linkText2: 'View Instructions: Add Kardiachain Testnet Network',
  linkURL2: 'https://docs.kardiachain.io/docs/for-users/wallet/metamask-compatible'
}

const project3: Project = {
  id: '3',
  name: 'Todays Sales',
  type: 'Mobile Application',
  description:
    'Todays Sales is a mobile application for the field retail analyst.' +
    '</br>It provides a live look at sales transactions by day, by hour for multiple retail stores.',
  tech: 'flutter, C#, MSSQL',
  textColor: '#316DC1',
  themeColor: '#13479E',
  thumb: imgProject3,
  linkText1: 'No link available [Private Project]',
  linkURL1: '',
  linkText2: '',
  linkURL2: ''
}

const project4: Project = {
  id: '4',
  name: 'Show Battery Percentage in Taskbar',
  type: 'Windows Application',
  description:
    'Show Battery Percentage In Taskbar is a lightweight program that shows you the current charging status and percentage value of your laptop battery.' +
    '</br>This provides the battery percentage value that is missing in Windows 10.',
  tech: 'C#',
  textColor: '#8DD43B',
  themeColor: '#4C7220',
  thumb: imgProject4,
  linkText1: 'Download Now',
  linkURL1: 'https://drive.proton.me/urls/V195F7P4YW#gVforZSaPwq5',
  linkText2: '',
  linkURL2: ''
}

const project5: Project = {
  id: '5',
  name: 'Command Central',
  type: 'Windows Application',
  description:
    'Command Central is a hybrid between the command prompt and the windows run command.' +
    '</br>Made for the advanced user who prefers the keyboard over the mouse, and who loves using shortcuts.' +
    '</br>Create your own shortcuts with their own command names like "fox" for launching firefox or call existing native commands like "regedit".',
  tech: 'C#',
  textColor: 'white',
  themeColor: 'grey',
  thumb: imgProject5,
  linkText1: 'Download Now',
  linkURL1: 'https://drive.proton.me/urls/6QNE9EMWPG#389wgdVQNTvw',
  linkText2: '',
  linkURL2: ''
}

const project6: Project = {
  id: '6',
  name: 'File Extension Rename Tool',
  type: 'Windows Application',
  description:
    'File Extension Rename Tool is a very light and simple to use tool which allows you to rename, append or remove file extensions in bulk.' +
    '</br>You can make the changes directly to the source files, or choose to create a copy.',
  tech: 'C#',
  textColor: '#FDAFDC',
  themeColor: '#951E88',
  thumb: imgProject6,
  linkText1: 'Download Now',
  linkURL1: 'https://drive.proton.me/urls/TDYTBVM3JG#GMhnXtMid11K',
  linkText2: '',
  linkURL2: ''
}

const project7: Project = {
  id: '7',
  name: 'Birthday Reminder',
  type: 'Windows Application',
  description:
    'Use this small but powerful application to get reminders everytime a birthday of someone you know is approaching.' +
    '</br>Get reminders as far ahead as you would like so that you can plan something special, or snooze until the day of so you don\'t forget to send that quick text.',
  tech: 'C#',
  textColor: '#7DA6DE',
  themeColor: '#175C99',
  thumb: imgProject7,
  linkText1: 'Download Now',
  linkURL1: 'https://drive.proton.me/urls/KEPMKBA5Q8#Wg5nT9En28tA',
  linkText2: '',
  linkURL2: ''
}

const project8: Project = {
  id: '8',
  name: 'Windows Up-Time Alarm',
  type: 'Windows Application',
  description:
    'This application provides information about the last time the system was rebooted.' +
    '</br>You can set an alarm to notify you when the system has been up for a certain amount of days.' +
    '</br>This can be useful in case you need to schedule a reboot and want to save your work prior, or simply for running a healthy system.',
  tech: 'C#',
  textColor: '#2EDF4B',
  themeColor: '#136D22',
  thumb: imgProject8,
  linkText1: 'Download Now',
  linkURL1: 'https://drive.proton.me/urls/YM6VXYY2JM#WGmrEGitGHR5',
  linkText2: '',
  linkURL2: ''
}


const project9: Project = {
  id: '9',
  name: 'Ahadith Organizer',
  type: 'Windows Application',
  description:
    'Ahadith Organizer contains a collection of the major authentic hadith books (Sahih Al Bukhari, Sahih Muslim, Malik\'s Muwatta, Sunan Abu-Dawud and the Forty Hadith Qudsi).' +
    '</br>Use Ahadith Organizer to read, make personal notes, save favourites, and set a bookmark.' +
    '</br>It also contains a powerful global search through every single hadith available based on any keyword, as well as a search within a single category.',
  tech: 'C#',
  textColor: '#EAE491',
  themeColor: '#136D22',
  thumb: imgProject9,
  linkText1: 'Download Now',
  linkURL1: 'https://drive.proton.me/urls/02STJAW3RW#rI7V6SJJES4a',
  linkText2: '',
  linkURL2: ''
}

const project10: Project = {
  id: '10',
  name: 'Outlook Desktop Mail Alerts',
  type: 'Windows Application',
  description:
    'Microsoft Outlook\'s default behavior does not include subfolder notification, but we\'ve fixed that with Outlook Desktop Mail Alerts.' +
    '</br>Use Outlook Desktop Mail Alerts to get notifications for new mail in your inbox and all sub folders.' +
    '</br>This floating widget utility keeps you focused on the task at hand without having to manually switch back-and-forth between your real work and Outlook.',
  tech: 'C#',
  textColor: '#4FCADD',
  themeColor: '#0A66B7',
  thumb: imgProject10,
  linkText1: 'Download Now',
  linkURL1: 'https://drive.proton.me/urls/WQ1XQHT2PC#i3xPbAqZr9Ej',
  linkText2: '',
  linkURL2: ''
}



export function useProjects() {

  const projectList: Project[] = [];
  projectList.push(project1);
  projectList.push(project2);
  projectList.push(project3);
  projectList.push(project10);
  projectList.push(project4);
  projectList.push(project5);
  projectList.push(project6);
  projectList.push(project7);
  projectList.push(project8);
  projectList.push(project9);


  return { projectList, PROJECTS_TITLE, PROJECTS_DESCRIPTION }
};