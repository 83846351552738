import { Box, HStack, Text, VStack, Link, Image } from "@chakra-ui/react";

// Hooks
import { useTheme } from "../hooks/useTheme";
import { useBusiness } from "../hooks/useBusiness";






/***********************************************************************************************************
 * COMPONENT START
 ***********************************************************************************************************/
const Footer = () => {

  const { colors, fonts, sizes } = useTheme();
  const { card, imgLogoL } = useBusiness();

  let currentYear = new Date().getFullYear()


  /***********************************************************************************************************
   * UI
   ***********************************************************************************************************/
  return (
    <Box id='footer'
      pt={[sizes.FTR_PTB_S, sizes.FTR_PTB_S, sizes.FTR_PTB_L, sizes.FTR_PTB_L]}
      pb={[sizes.FTR_PTB_S, sizes.FTR_PTB_S, sizes.FTR_PTB_L, sizes.FTR_PTB_L]}
      width='100%'
    >


      <VStack
        width={"100%"}
        justifyContent={"center"}
        bgColor={colors.NAV_DESKTOP_BG}
        color={colors.NAV_DESKTOP_TXT}
        fontFamily={'heading'}
        fontSize={[fonts.FOOTER_MENU_S, fonts.FOOTER_MENU_S, fonts.FOOTER_MENU_L, fonts.FOOTER_MENU_L]}>

        <HStack spacing='10px' justifyContent={'left'} alignItems={'start'}
          width={[sizes.TERMINAL_W_S, sizes.TERMINAL_W_M, sizes.TERMINAL_W_L, sizes.TERMINAL_W_XL]}>

          {/* LOGO */}
          <Image ml='20px'
            width={[sizes.FTR_LOGO_WIDTH_S, sizes.FTR_LOGO_WIDTH_M, sizes.FTR_LOGO_WIDTH_L, sizes.FTR_LOGO_WIDTH_XL]}
            src={imgLogoL} />


          {/* BUS INFO */}
          <VStack
            width='100%' height='100%' spacing='4px'
            textAlign={'left'} justify='left' align='left'
            pl={['20px', '20px', '100px', '100px']}>

            <Text width='100%' fontWeight='bold' pb='10px'>{card.BUS_NAME}</Text>
            <Text><Link href={"#about"}>Who am I</Link></Text>
            <Text><Link href={"#technology"}>Technology</Link></Text>
            <Text><Link href={"#projects"}>Projects</Link></Text>
            <Text><Link href={"#contact"}>Contact</Link></Text>

          </VStack>


          {/* EXTERNAL LINKS */}
          <VStack width='100%' height='100%' spacing='4px'
            textAlign={'left'} justify='left' align='left'>

            <Text width='100%' fontWeight='bold' pb='10px'>INFORMATION</Text>
            <Text><Link href={card.LINK_LINKEDIN} target='_blank'>LinkedIn</Link></Text>
            <Text><Link href={card.LINK_GITHUB} target='_blank'>GitHub</Link></Text>
            <Text><Link href={card.LINK_MAILTO} target='_blank'>Email Me</Link></Text>

          </VStack>

        </HStack>

        {/* DESKTOP - COPYRIGHT */}
        <Text pt='50px' display={{ base: 'none', md: 'block' }}
          bgColor={colors.NAV_DESKTOP_BG}
          color={colors.NAV_DESKTOP_TXT}
          letterSpacing={1.2}
          fontFamily={'cmd'}
          fontSize={[fonts.FOOTER_COPYRIGHT_S, fonts.FOOTER_COPYRIGHT_S, fonts.FOOTER_COPYRIGHT_L, fonts.FOOTER_COPYRIGHT_L]}>
          Copyright © {currentYear} {card.BUS_NAME}. All rights reserved. | Designed by  <Link href={card.BUS_WEBSITE} target="_blank">Ramiware</Link>
          <Text>
            Email: {card.BUS_EMAIL}
          </Text>
        </Text>


        {/* MOBILE - COPYRIGHT */}
        <Text pt='50px' display={{ base: 'block', md: 'none' }}
          bgColor={colors.NAV_DESKTOP_BG}
          color={colors.NAV_DESKTOP_TXT}
          letterSpacing={1.2}
          fontFamily={'cmd'}
          fontSize={[fonts.FOOTER_COPYRIGHT_S, fonts.FOOTER_COPYRIGHT_S, fonts.FOOTER_COPYRIGHT_L, fonts.FOOTER_COPYRIGHT_L]}>
          <Text>Copyright © {currentYear} {card.BUS_NAME}. All rights reserved.</Text>
          <Text>
            {'> Designed by '} <Link href={card.BUS_WEBSITE} target="_self">Ramiware</Link>
          </Text>
          <Text>
            Email: {card.BUS_EMAIL}
          </Text>
        </Text>

      </VStack>


    </Box>
  );
};
export default Footer;