/***********************************************************************************************************
 * COMPONENT START
 ***********************************************************************************************************/
export function useVersion() {

  const SITE_NAME = "Rami Sorikh Portfolio";
  const VERSION = "Version 2.00";
  const COPYRIGHT = "(c) Ramiware. All rights reserved.";



  return { SITE_NAME, VERSION, COPYRIGHT }
};