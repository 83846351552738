import { Global } from '@emotion/react'


const Fonts = () => (
  <Global
    styles={`
    /* latin */
    @font-face {
      font-family: 'Fjalla One';
      /* font-style: normal; */
      /* font-weight: 700; */
      /* font-display: swap; */
      src: url('./fonts/Fjalla One.ttf') format('truetype');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
      /* latin */
      @font-face {
        font-family: 'sd prostreet Heading';
        /* font-style: normal; */
        /* font-weight: 700; */
        /* font-display: swap; */
        src: url('./fonts/sd prostreet.ttf') format('truetype'), url('./fonts/sd prostreet.ttf') format('truetype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin */
      @font-face {
        font-family: 'Impact Body';
        /* font-style: normal; */
        /* font-weight: 100; */
        /* font-display: swap; */
        src: url('./fonts/URW Impact W01 Bold Condensed.ttf') format('truetype'), url('./fonts/URW Impact W01 Bold Condensed.ttf') format('truetype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin */
      @font-face {
        font-family: 'Amazon Body';
        /* font-style: normal; */
        /* font-weight: 100; */
        /* font-display: swap; */
        src: url('./fonts/Amazon Ember Regular.ttf') format('truetype'), url('./fonts/Amazon Ember Regular.ttf') format('truetype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }          
      /* latin */
      @font-face {
        font-family: 'Oswald Body';
        /* font-style: normal; */
        /* font-weight: 100; */
        /* font-display: swap; */
        src: url('./fonts/Oswald.ttf') format('truetype'), url('./fonts/Oswald.ttf') format('truetype');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }    
      /* latin */
      @font-face{
        font-family: "Windows Command Prompt Regular";
        src: url("./fonts/Windows/e6d22d83684a3a24992cd65c0f769e1e.eot");
        src: url("./fonts/Windows/e6d22d83684a3a24992cd65c0f769e1e.eot?#iefix")format("embedded-opentype"),
            url("./fonts/Windows/e6d22d83684a3a24992cd65c0f769e1e.woff")format("woff"),
            url("./fonts/Windows/e6d22d83684a3a24992cd65c0f769e1e.woff2")format("woff2"),
            url("./fonts/Windows/e6d22d83684a3a24992cd65c0f769e1e.ttf")format("truetype"),
            url("./fonts/Windows/e6d22d83684a3a24992cd65c0f769e1e.svg#Windows Command Prompt Regular")format("svg");
        font-weight:normal;
        font-style:normal;
        font-display:swap;
      }
      @font-face{
        font-family: "Consolas";
        src: url("./fonts/Consolas/1db29588408eadbd4406aae9238555eb.eot");
        src: url("./fonts/Consolas/1db29588408eadbd4406aae9238555eb.eot?#iefix")format("embedded-opentype"),
            url("./fonts/Consolas/1db29588408eadbd4406aae9238555eb.woff")format("woff"),
            url("./fonts/Consolas/1db29588408eadbd4406aae9238555eb.woff2")format("woff2"),
            url("./fonts/Consolas/1db29588408eadbd4406aae9238555eb.ttf")format("truetype"),
            url("./fonts/Consolas/1db29588408eadbd4406aae9238555eb.svg#Consolas")format("svg");
        font-weight:normal;
        font-style:normal;
        font-display:swap;
      }
      @font-face{
        font-family: "Consolas Bold";
        src: url("./fonts/Consolas Bold/7682f23182f0dfc5c2df3b6514a6a7bf.eot");
        src: url("./fonts/Consolas Bold/7682f23182f0dfc5c2df3b6514a6a7bf.eot?#iefix")format("embedded-opentype"),
            url("./fonts/Consolas Bold/7682f23182f0dfc5c2df3b6514a6a7bf.woff")format("woff"),
            url("./fonts/Consolas Bold/7682f23182f0dfc5c2df3b6514a6a7bf.woff2")format("woff2"),
            url("./fonts/Consolas Bold/7682f23182f0dfc5c2df3b6514a6a7bf.ttf")format("truetype"),
            url("./fonts/Consolas Bold/7682f23182f0dfc5c2df3b6514a6a7bf.svg#Consolas Bold")format("svg");
        font-weight:normal;
        font-style:normal;
        font-display:swap;
      }
    
      `}
  />
)

export default Fonts