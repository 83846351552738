import { Box, Flex, Link, Text, VStack } from "@chakra-ui/react";
import { Key, } from "react";

// Hooks
import { useTheme } from "../hooks/useTheme";
import { useTechStack } from "../hooks/useTechStack";

// Components
import SectionHeader from "../components/SectionHeader";
import { motion } from "framer-motion";



/***********************************************************************************************************
 * COMPONENT START
 ***********************************************************************************************************/
const Technology = () => {

  const { colors, sizes, fonts } = useTheme();
  const { TECH_STACK_TITLE, TECH_STACK_DESCRIPTION, techStackListFE, techStackListBE, techStackListW3, } = useTechStack();

  const TECH_BORDER_RADIUS = 'full';

  ////////////////////////////////////////////////////
  /// Return the tech list bubble mapping
  ////////////////////////////////////////////////////
  function GetTechListBubbles(techType: string, motionSeq: number[], techList: any[]) {
    return (
      <VStack>
        <motion.div
          initial={{ x: 0 }}
          animate={{ x: motionSeq }}
          transition={{ duration: 60.0, repeat: Infinity }}
        >
          <Text
            fontSize={fonts.T2_SIZE_S}
            fontFamily={'cmd'}>
            {techType}
          </Text>
        </motion.div>

        <Flex gap={'10px'} pb='20px' flexWrap='wrap' justify='center'
          fontFamily={'cmd'} fontStyle={'normal'}
          fontSize={[fonts.T2_SIZE_S, fonts.T2_SIZE_S, fonts.T2_SIZE_L, fonts.T2_SIZE_L]}>
          {techList.map((tech, index: Key) => (
            <>
              <motion.div
                initial={{ opacity: 1, scale: 1, }}
                animate={{ opacity: 1, scale: [1, 1.08, 1], }}
                transition={{ delay: Number(index), duration: 1.1, repeat: Infinity }}>

                <Link href={tech.linkURL} isExternal>
                  <motion.div
                    whileHover={{ scale: 1.2 }}>
                    <Box
                      bgColor={tech.bgColor}
                      borderRadius={TECH_BORDER_RADIUS}>
                      <Text key={index}
                        alignContent={'center'}
                        height={sizes.BUBBLE_HEIGHT}
                        fontSize={fonts.BUBBLE_SIZE}
                        color={tech.textColor}
                        pt='2px' pb='2px' pl='15px' pr='15px'>
                        {tech.name}
                      </Text>
                    </Box>
                  </motion.div>
                </Link>

              </motion.div>
            </>
          ))}
        </Flex>
      </VStack>
    )
  }

  /***********************************************************************************************************
   * RENDER
   ***********************************************************************************************************/
  return (
    <Box id='technology'
      // borderTopWidth={'1px'}
      // borderTopColor={colors.DESKTOP_DIVIDER}
      pt={[sizes.SECTION_PTB_S, sizes.SECTION_PTB_S, sizes.SECTION_PTB_L, sizes.SECTION_PTB_L]}
      pb={[sizes.SECTION_PTB_S, sizes.SECTION_PTB_S, sizes.SECTION_PTB_L, sizes.SECTION_PTB_L]}
      bgGradient={colors.TECHNOLOGY_GRADIENT_BG}
      color={colors.NAV_DESKTOP_TXT}
      width='100%'
    >

      {/* SECTION TITLE */}
      <SectionHeader title={TECH_STACK_TITLE} description={TECH_STACK_DESCRIPTION} />

      {/* TECH STACK LISTS */}
      {GetTechListBubbles("Frontend", [-150, 150, -150], techStackListFE)}
      {GetTechListBubbles("Backend", [120, -120, 120], techStackListBE)}
      {GetTechListBubbles("Web3", [-80, 80, -80], techStackListW3)}


    </Box>
  );
};
export default Technology;