import { Box, } from "@chakra-ui/react";

// Hooks
import { useTheme } from "../hooks/useTheme";
import { useProjects } from "../hooks/useProjects";

// Components
import Feature from "../components/Feature";
import SectionHeader from "../components/SectionHeader";



/***********************************************************************************************************
 * COMPONENT START
 ***********************************************************************************************************/
const Projects = () => {

  const { colors, sizes } = useTheme();
  const { projectList, PROJECTS_TITLE, PROJECTS_DESCRIPTION } = useProjects();


  /***********************************************************************************************************
   * UI
   ***********************************************************************************************************/
  return (
    <Box id='projects'
      // borderTopWidth={'1px'}
      // borderTopColor={colors.DESKTOP_DIVIDER}
      pt={[sizes.SECTION_PTB_S, sizes.SECTION_PTB_S, sizes.SECTION_PTB_L, sizes.SECTION_PTB_L]}
      pb={[sizes.SECTION_PTB_S, sizes.SECTION_PTB_S, sizes.SECTION_PTB_L, sizes.SECTION_PTB_L]}
      bgGradient={colors.PROJECTS_GRADIENT_BG1}
      color={colors.NAV_DESKTOP_TXT}
      width='100%'
    >

      {/* SECTION TITLE */}
      <SectionHeader title={PROJECTS_TITLE} description={PROJECTS_DESCRIPTION} />


      {/* SECTION BODY */}
      {projectList.map((project, index) => (
        <Box key={index} pb='100px'>
          <Feature projectName={project.name}
            projectType={project.type}
            description={project.description}
            techUsed={project.tech}
            textColor={project.textColor}
            themeColor={project.themeColor}
            image={project.thumb}
            linkText1={project.linkText1}
            linkURL1={project.linkURL1}
            linkText2={project.linkText2}
            linkURL2={project.linkURL2}
            setImageFirst={(index % 2 === 0 ? true : false)} />
        </Box>
      ))}

    </Box>
  );
};
export default Projects;