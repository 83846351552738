import { extendTheme } from '@chakra-ui/react'



/***********************************************************************************************************
 * COMPONENT START
 ***********************************************************************************************************/
const Theme = extendTheme({
  fonts: {
    heading: `'Fjalla One',  Calibri`,
    terminal: `'Windows Command Prompt Regular', Impact`,
    cmd: `'Consolas'`,
    cmdBold: `'Consolas Bold'`,
    bodyTitle: `'Impact Body', Impact`,
    // bodyParagraph: `'Oswald Body',  Calibri`,
  },
  initialColorMode: "dark",
  useSystemColorMode: false,

})

export default Theme


// // 1. import `extendTheme` function
// import { extendTheme, type ThemeConfig } from "@chakra-ui/react";

// // 2. Add your color mode config
// const config: ThemeConfig = {
//   initialColorMode: "dark",
//   useSystemColorMode: false,
  
// };

// // 3. extend the theme
// const theme = extendTheme({ config });

// export default theme;
