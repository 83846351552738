import { Box, Center, HStack, Text, VStack, Link, Image, Input } from "@chakra-ui/react";
import { GrLinkedinOption, GrGithub, GrMail } from "react-icons/gr";
import { useEffect, useState } from "react";

// Assets
import monitorBG from "../assets//images/contact/monitor-1024x1024.png";
import keyboardBG from "../assets//images/contact/keyboard-1024x1024.png";

import key1IMG from "../assets//images/contact/key1-260x115.png";
import key2IMG from "../assets//images/contact/key2-260x115.png";
import key3IMG from "../assets//images/contact/key3-260x115.png";

import key1HoverIMG from "../assets//images/contact/key1-mousehover-260x115.png";
import key2HoverIMG from "../assets//images/contact/key2-mousehover-260x115.png";
import key3HoverIMG from "../assets//images/contact/key3-mousehover-260x115.png";

import key1DownIMG from "../assets//images/contact/key1-mousedown-260x115.png";
import key2DownIMG from "../assets//images/contact/key2-mousedown-260x115.png";
import key3DownIMG from "../assets//images/contact/key3-mousedown-260x115.png";

// Hooks
import { useTheme } from "../hooks/useTheme";
import { useBusiness } from "../hooks/useBusiness";

// Components
import SectionHeader from "../components/SectionHeader";




/***********************************************************************************************************
 * COMPONENT START
 ***********************************************************************************************************/
const Contact = () => {

  const SECTION_TITLE = "Contact";
  const SECTION_DESCRIPTION = "I'm always up for a chat.<br>Shoot me an email if you want to do a collab, hire me, or simply have a question!";

  const SCREEN_INSTRUCT = "Please enter one of the following options in the box below:";
  const SCREEN_OPT1 = "1) LinkedIn";
  const SCREEN_OPT2 = "2) GitHub";
  const SCREEN_OPT3 = "3) Contact me (Email)";
  const SCREEN_INPUT_INSTRUCT = "Enter #:";
  const SCREEN_EXEC_INSTRUCT = "-> Press Enter";

  const { colors, fonts, sizes } = useTheme();
  const { card } = useBusiness();

  const [inputValue, setInputValue] = useState('')

  const hrefOption1 = card.LINK_LINKEDIN;
  const hrefOption2 = card.LINK_GITHUB;
  const hrefOption3 = card.LINK_MAILTO;

  const [image1, setImage1] = useState(key1IMG);
  const [image2, setImage2] = useState(key2IMG);
  const [image3, setImage3] = useState(key3IMG);

  //////////////////////////////////////////////////////
  /// HANDLE USER INPUT
  //////////////////////////////////////////////////////
  function processUserInput() {
    console.log("processUserInput: " + inputValue);

    if (inputValue === "1")
      window.open(hrefOption1, '_blank')?.focus();
    if (inputValue === "2")
      window.open(hrefOption2, '_blank')?.focus();
    if (inputValue === "3")
      window.open(hrefOption3, '_blank')?.focus();
  }

  //////////////////////////////////////////////////////
  /// HANDLE USER CLICK
  //////////////////////////////////////////////////////
  function processUserClick(selection: string) {
    console.log("processUserClick:", selection);

    if (selection === "1")
      window.open(hrefOption1, '_blank')?.focus();
    if (selection === "2")
      window.open(hrefOption2, '_blank')?.focus();
    if (selection === "3")
      window.open(hrefOption3, '_blank')?.focus();
  }

  useEffect(() => {
  }, [inputValue]);

  useEffect(() => {
  }, [image1, image2, image3]);

  /***********************************************************************************************************
   * UI
   ***********************************************************************************************************/
  return (
    <Box id='contact'
      // borderTopWidth={'1px'}
      // borderTopColor={colors.DESKTOP_DIVIDER}
      pt={[sizes.SECTION_PTB_S, sizes.SECTION_PTB_S, sizes.SECTION_PTB_L, sizes.SECTION_PTB_L]}
      pb={[sizes.SECTION_PTB_S, sizes.SECTION_PTB_S, sizes.SECTION_PTB_L, sizes.SECTION_PTB_L]}
      bgGradient={colors.CONTACT_GRADIENT_BG}
      // bgColor={colors.TXT_BODY2}
      color={colors.TXT_BODY1}
      width='100%'
    >


      {/* SECTION TITLE */}
      <SectionHeader title={SECTION_TITLE} description={SECTION_DESCRIPTION} />


      {/* SECTION BODY */}
      <Center>
        <VStack spacing='0px'
          justifyContent={'center'}
          boxShadow='lg' rounded={sizes.SECTION_BOX_RADIUS}
        >
          {/* TERMINAL */}
          <Box
            bgImg={monitorBG}
            bgRepeat={'no-repeat'}
            bgPos={'center'}
            bgSize='contain'
            boxSize={[sizes.CONTACT_COMP_BOXSIZE_S, sizes.CONTACT_COMP_BOXSIZE_M, sizes.CONTACT_COMP_BOXSIZE_L, sizes.CONTACT_COMP_BOXSIZE_XL]}
            color={colors.ACCENT_BODY1}
            fontFamily={'cmd'} fontStyle={'normal'}
            fontSize={[fonts.T2_SIZE_S, fonts.T2_SIZE_S, fonts.T2_SIZE_L, fonts.T2_SIZE_L]}
            pt={['60px', '60px', '80px', '80px']}
            pl={['55px', '55px', '75px', '75px']}
            pr={['55px', '55px', '75px', '75px']}
          // bgColor='blue'

          >
            <VStack width='100%'>

              {/* LINKS */}
              <Center borderColor={colors.ACCENT_BODY1} borderWidth={'2px'} borderStyle='dashed'
                width='100%'
              >
                <HStack spacing='20px' pt='5px' pb='5px'>
                  <Link href={card.LINK_LINKEDIN} target='_blank'>
                    <GrLinkedinOption size={sizes.ICON_L} />
                  </Link>

                  <Link href={card.LINK_GITHUB} target='_blank'>
                    <GrGithub size={sizes.ICON_L} />
                  </Link>

                  <Link href={card.LINK_MAILTO} target='_blank'>
                    <GrMail size={sizes.ICON_L} />
                  </Link>
                </HStack>
              </Center>


              {/* TEXT / INPUT */}
              <Box
                textAlign='left'
              >
                <VStack align='left' spacing='0px' mt={{ base: '5px', md: '10px' }}>
                  <Text mb={{ base: '10px', md: '10px' }}>{SCREEN_INSTRUCT}</Text>
                  <Text>{SCREEN_OPT1}</Text>
                  <Text>{SCREEN_OPT2}</Text>
                  <Text mb={{ base: '10px', md: '10px' }}>{SCREEN_OPT3}</Text>
                  <HStack spacing='10px'>
                    <Text>
                      {SCREEN_INPUT_INSTRUCT}
                    </Text>
                    <Input type='text' name="userOption" required={true} maxLength={1}
                      bgColor='black'
                      focusBorderColor={colors.ACCENT_BODY1}
                      borderColor={colors.ACCENT_BODY1}
                      borderWidth={'1px'}
                      borderRadius={'none'}
                      width={{ base: '45px', md: '55px' }}
                      height='25px'
                      value={inputValue}
                      onChange={(e) => setInputValue(e.currentTarget.value)}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          processUserInput();
                        }
                      }}
                    />
                    <Text>
                      {SCREEN_EXEC_INSTRUCT}
                    </Text>
                  </HStack>
                </VStack>

              </Box>
            </VStack>
          </Box>

          {/* KEYBOARD */}
          <Box
            width={[sizes.CONTACT_KEYBOARD_W_S, sizes.CONTACT_KEYBOARD_W_S, sizes.CONTACT_KEYBOARD_W_L, sizes.CONTACT_KEYBOARD_W_L]}
            height={[sizes.CONTACT_KEYBOARD_H_S, sizes.CONTACT_KEYBOARD_H_S, sizes.CONTACT_KEYBOARD_H_L, sizes.CONTACT_KEYBOARD_H_L]}
            bgImg={keyboardBG}
            bgRepeat={'no-repeat'}
            bgPos={'center'}
            bgSize='contain'
          >

            <HStack justify='center'
              spacing='1px' pt={['8px', '8px', '10px', '10px']}
              width={[sizes.CONTACT_KEYBOARD_W_S, sizes.CONTACT_KEYBOARD_W_S, sizes.CONTACT_KEYBOARD_W_L, sizes.CONTACT_KEYBOARD_W_L]}>

              <Image
                width={[sizes.CONTACT_KEY_W_S, sizes.CONTACT_KEY_W_S, sizes.CONTACT_KEY_W_L, sizes.CONTACT_KEY_W_L]}
                onClick={() => processUserClick("1")}
                src={image1}
                onMouseEnter={() => setImage1(key1HoverIMG)}
                onMouseOut={() => setImage1(key1IMG)}
                onMouseDown={() => setImage1(key1DownIMG)}
                _hover={{ transform: "scale(1.03)", }}
                _active={{ transform: "scale(0.75)", }}
                transition={"0.2s ease-in-out"} />

              <Image width={[sizes.CONTACT_KEY_W_S, sizes.CONTACT_KEY_W_S, sizes.CONTACT_KEY_W_L, sizes.CONTACT_KEY_W_L]}
                onClick={() => processUserClick("2")}
                src={image2}
                onMouseEnter={() => setImage2(key2HoverIMG)}
                onMouseOut={() => setImage2(key2IMG)}
                onMouseDown={() => setImage2(key2DownIMG)}
                _hover={{ transform: "scale(1.03)", }}
                _active={{ transform: "scale(0.75)", }}
                transition={"0.2s ease-in-out"} />

              <Image width={[sizes.CONTACT_KEY_W_S, sizes.CONTACT_KEY_W_S, sizes.CONTACT_KEY_W_L, sizes.CONTACT_KEY_W_L]}
                onClick={() => processUserClick("3")}
                src={image3}
                onMouseEnter={() => setImage3(key3HoverIMG)}
                onMouseOut={() => setImage3(key3IMG)}
                onMouseDown={() => setImage3(key3DownIMG)}
                _hover={{ transform: "scale(1.03)", }}
                _active={{ transform: "scale(0.75)", }}
                transition={"0.2s ease-in-out"} />
            </HStack>
          </Box>
        </VStack>
      </Center>


    </Box >
  );
};
export default Contact;