import { Box, Image, VStack, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { ScrambledText } from '@kamrade/react-scrambled-text';

// Assets
import imgCommodore from "../assets/images/hero/commodore-1024x1024.png"

// Hooks
import { useTheme } from "../hooks/useTheme";
import { useBusiness } from "../hooks/useBusiness";



/***********************************************************************************************************
 * COMPONENT START
 ***********************************************************************************************************/
const Computer = () => {

  const { colors, fonts, sizes } = useTheme();
  const { imgLogoL } = useBusiness();

  const textValues: string[] = [
    "I AM AN ANALYST",
    "I AM A COLLABORATOR",
    "I AM A PROGRAMMER",
    "I AM A DEVELOPER",
    "I AM A DESIGNER",
    "I AM AN ARCHITECT",
  ];

  const HOMAGE_TXT = "Paying homage to my first computer, the Commodore PC 20-II";


  /***********************************************************************************************************
   * UI
   ***********************************************************************************************************/
  return (

    <Box>

      {/* COMPUTER IMAGE */}
      <motion.div
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1, }}
        transition={{ delay: 4.0, duration: 3, }}
      >
        {/* COMPUTER IMAGE */}
        <VStack
          boxSize={[sizes.HERO_COMP_BOXSIZE_S, sizes.HERO_COMP_BOXSIZE_M, sizes.HERO_COMP_BOXSIZE_L, sizes.HERO_COMP_BOXSIZE_XL]}
          bgImg={imgCommodore}
          bgRepeat={'no-repeat'}
          bgSize={'contain'}
          bgPosition={'center'}
        >

          {/* RW LOGO IMG/TXT */}
          <motion.div
            // initial={{ opacity: 1, x: 0, scale: 1 }}
            // animate={{ opacity: 1, x: 0, scale: 1, rotate: 360 }}
            // transition={{ delay: 3, ease: "linear", duration: 20, repeat: Infinity }}
            initial={{ opacity: 0, x: 0, scale: 1 }}
            animate={{ opacity: [0, 1, 0], x: [0, 0, 0], scale: [1.1] }}
            transition={{ delay: 0, ease: "easeOut", duration: [5.0], repeat: Infinity }}
          >
            <Image src={imgLogoL}
              mt={[sizes.HERO_RW_LOGO_PT_S, sizes.HERO_RW_LOGO_PT_M, sizes.HERO_RW_LOGO_PT_L, sizes.HERO_RW_LOGO_PT_XL]}
              boxSize={[sizes.HERO_RW_LOGO_WIDTH_S, sizes.HERO_RW_LOGO_WIDTH_M, sizes.HERO_RW_LOGO_WIDTH_L, sizes.HERO_RW_LOGO_WIDTH_XL]}
            />
          </motion.div>

          {/* I AM ___ */}
          <motion.div
            initial={{ opacity: 0, x: 0, scale: 0.8 }}
            animate={{ opacity: 1, x: 0, scale: 1, }}
            transition={{ delay: 3, duration: 3, }}
          >

            <Box fontFamily={'terminal'} color={colors.ACCENT_BODY1}
              fontSize={[fonts.HERO_P_S, fonts.HERO_P_M, fonts.HERO_P_L, fonts.HERO_P_XL]}>
              <ScrambledText
                value={textValues}
                slideLength={2000}
                postAnimate
                postAnimateSensetivity={50}
              ></ScrambledText>
            </Box>

          </motion.div>

          <Text mt={['150px', '150px', '205px', '205px']}
            fontFamily='cmd'
            fontSize={[fonts.HERO_HOMAGE_S, fonts.HERO_HOMAGE_M, fonts.HERO_HOMAGE_L, fonts.HERO_HOMAGE_XL]}>
            {HOMAGE_TXT}
          </Text>
        </VStack>
      </motion.div>


    </Box >


  );

};

export default Computer;