
/***********************************************************************************************************
 * COMPONENT START
 ***********************************************************************************************************/
export function useTheme() {

  // ************************************************
  // COLORS
  // ************************************************
  const colors = {
    // NAVIGATION
    NAV_DESKTOP_BG: 'black',
    NAV_DESKTOP_TXT: 'white',

    NAV_MOBILE_BG: '#121212', // grey
    NAV_MOBILE_TXT: '#39B54A', // lightest green,
    NAV_DIVIDER: '#39B54A', // lightest green,

    // ABOUT
    ABOUT_GRADIENT_BG: 'radial(#002800, black)', // green to black

    // TECHNOLOGY
    TECHNOLOGY_GRADIENT_BG: 'radial(black, #002800)', // green to black

    // PROJECTS
    PROJECTS_GRADIENT_BG1: 'linear(to-b, black, #002800, black)', // black to green
    PROJECTS_GRADIENT_BG2: 'linear(to-b, #002800, black)', // black to green

    // CONTACT
    CONTACT_GRADIENT_BG: 'linear(to-t,#002800, black)', // green to black

    // BODY
    BG_BODY1: 'black',
    TXT_BODY1: 'white',

    BG_BODY2: '#A0DEA8', // mint green
    TXT_BODY2: '#00A14B', // soft green

    ACCENT_BODY1: '#39B54A', // lightest green,
    ACCENT_BODY2: '#057C25', // medium green

    DESKTOP_DIVIDER: '#057C25', // medium green

  }


  // ************************************************
  // FONTS
  // ************************************************
  const fonts = {
    // NAVIGATION
    BUTTON_S: '11px', //*
    BUTTON_L: '16px',//*

    NAV_S: '20px', //* Mobile nav
    NAV_L: '20px', //* Desktop nav

    //HERO
    HERO_H1_S: '70px', //*
    HERO_H1_M: '70px',
    HERO_H1_L: '100px',
    HERO_H1_XL: '150px',

    HERO_H2_S: '70px', //*
    HERO_H2_M: '70px',
    HERO_H2_L: '85px',
    HERO_H2_XL: '130px',

    HERO_H3_S: '11px', //*
    HERO_H3_M: '11px',
    HERO_H3_L: '14px',
    HERO_H3_XL: '21px',

    HERO_HOMAGE_S: '9px', //*
    HERO_HOMAGE_M: '9px',
    HERO_HOMAGE_L: '10px',
    HERO_HOMAGE_XL: '10px',


    HERO_P_S: '14px', //*
    HERO_P_M: '14px',
    HERO_P_L: '14px',
    HERO_P_XL: '16px',

    // SECTION TITLES
    H1_SIZE_S: '36px', //*
    H1_SIZE_L: '36px',

    // SECTION DESCRIPTIONS
    H2_SIZE_S: '14px', //*
    H2_SIZE_L: '16px',

    // TERMINAL TITLE
    T1_SIZE_S: '14px', //*
    T1_SIZE_L: '16px',

    // TERMINAL BODY
    T2_SIZE_S: '13px', //*
    T2_SIZE_L: '15px',

    // BUBBLE SIZES
    BUBBLE_SIZE: '12px',


    // FOOTER MENU
    FOOTER_MENU_S: '13px',
    FOOTER_MENU_L: '16px',

    FOOTER_COPYRIGHT_S: '11px',
    FOOTER_COPYRIGHT_L: '12px',
  }


  // ************************************************
  // SIZES
  // ************************************************
  const sizes = {
    // NAVIGATION
    NAV_HEIGHT_S: '55px', //*
    NAV_HEIGHT_L: '75px',

    NAV_LOGO_WIDTH_S: '40px',
    NAV_LOGO_WIDTH_L: '60px',

    NAV_TEXT_WIDTH_S: '180px', //*
    NAV_TEXT_WIDTH_L: '180px',

    // HERO
    HERO_MT_S: '50px',
    HERO_MT_L: '75px',

    HERO_COMP_BOXSIZE_S: '320px', //*
    HERO_COMP_BOXSIZE_M: '320px',
    HERO_COMP_BOXSIZE_L: '450px',
    HERO_COMP_BOXSIZE_XL: '450px',

    HERO_RW_LOGO_WIDTH_S: '70px', //*
    HERO_RW_LOGO_WIDTH_M: '70px',
    HERO_RW_LOGO_WIDTH_L: '110px',
    HERO_RW_LOGO_WIDTH_XL: '110px',

    HERO_RW_LOGO_PT_S: '48px', //*
    HERO_RW_LOGO_PT_M: '48px',
    HERO_RW_LOGO_PT_L: '70px',
    HERO_RW_LOGO_PT_XL: '70px',

    // SECTION HEADER
    SECHDR_W_S: '350px', //*
    SECHDR_W_M: '350px',
    SECHDR_W_L: '750px',
    SECHDR_W_XL: '750px',

    // SECTIONS
    SECTION_PTB_S: '70px', //*
    SECTION_PTB_L: '100px', //*

    SECTION_W_S: '100%', //*
    SECTION_W_M: '100%',
    SECTION_W_L: '1245px',
    SECTION_W_XL: '1245px',

    SECTION_BOX_RADIUS: 'xl',

    // ABOUT / PROJECTS
    TERMINAL_W_S: '350px', //*
    TERMINAL_W_M: '350px',
    TERMINAL_W_L: '750px',
    TERMINAL_W_XL: '750px',

    TERMINAL_H_S: '480px', //*
    TERMINAL_H_M: '480px',
    TERMINAL_H_L: '420px',
    TERMINAL_H_XL: '420px',

    PHOTO_BOXSIZE_S: '350px', //*
    PHOTO_BOXSIZE_M: '350px',
    PHOTO_BOXSIZE_L: '460px',
    PHOTO_BOXSIZE_XL: '460px',

    // TECHNOLOGY
    BUBBLE_HEIGHT: '35px',

    // CONTACT
    CONTACT_COMP_BOXSIZE_S: '350px', //*
    CONTACT_COMP_BOXSIZE_M: '350px',
    CONTACT_COMP_BOXSIZE_L: '450px',
    CONTACT_COMP_BOXSIZE_XL: '450px',

    ICON_S: 30,
    ICON_L: 35,

    CONTACT_KEYBOARD_W_S: '350px', //*
    CONTACT_KEYBOARD_W_L: '610px',

    CONTACT_KEYBOARD_H_S: '70px', //*
    CONTACT_KEYBOARD_H_L: '120px',

    CONTACT_KEY_W_S: '100px', //*
    CONTACT_KEY_W_L: '170px',

    // FOOTER
    FTR_LOGO_WIDTH_S: '100px',
    FTR_LOGO_WIDTH_M: '100px',
    FTR_LOGO_WIDTH_L: '150px',
    FTR_LOGO_WIDTH_XL: '150px',

    FTR_PTB_S: '70px', //*
    FTR_PTB_L: '50px', //*


  }




  return { colors, fonts, sizes }
};