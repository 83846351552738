import { Box, Flex } from "@chakra-ui/react";

// Components
import MatrixEffect from "../components/MatrixEffect";
import Navigation from "../sections/Navigation";
import Hero from "../sections/Hero";
import About from "../sections/About";
import Technology from "../sections/Technology";
import Projects from "../sections/Projects";
import Contact from "../sections/Contact";

import Counter from "../components/Counter";
import Footer from "../sections/Footer";


/***********************************************************************************************************
 * COMPONENT START
 ***********************************************************************************************************/
const Home = () => {


  /***********************************************************************************************************
   * UI
   ***********************************************************************************************************/
  return (
    <Box width='100%' bgColor='black'>


      <Box zIndex={0} position={'absolute'} m={'0px'} p={'0px'}>
        <MatrixEffect />
      </Box>

      <Flex
        position={'relative'}
        m={'0px'} gap={['0px', '0px', '0px', '0px']} flexWrap={"wrap"}
        width={'100%'}>

        <Navigation />
        <Hero />
        <About />
        <Technology />
        <Projects />
        <Contact />
        <Footer />
        <Counter />


      </Flex>

    </Box>

  );
};
export default Home;