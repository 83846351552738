


const TECH_STACK_TITLE = "Technology";
const TECH_STACK_DESCRIPTION =
  "This is a stack. This is a VStack and an HStack to be specific... or maybe it's a Flex? </br>This is my stack of choice.";

type Tech = {
  id: string,
  name: string,
  textColor: string,
  bgColor: string,
  linkURL: string,
}


////////////////////////////////////////////////////////////////
// TECHLIST FRONTEND
////////////////////////////////////////////////////////////////
const techFE_1: Tech = {
  id: '1',
  name: 'C#',
  textColor: 'white',
  bgColor: '#3949AB',
  linkURL: 'https://learn.microsoft.com/en-us/dotnet/csharp/',
}

const techFE_2: Tech = {
  id: '2',
  name: 'flutter',
  textColor: 'black',
  bgColor: '#47C5FB',
  linkURL: 'https://docs.flutter.dev/',
}

const techFE_3: Tech = {
  id: '3',
  name: 'motion',
  textColor: 'white',
  bgColor: '#F300AE',
  linkURL: 'https://www.framer.com/motion/',
}

const techFE_4: Tech = {
  id: '4',
  name: 'react',
  textColor: 'black',
  bgColor: '#00D8FF',
  linkURL: 'https://react.dev/',
}



const techFE_5: Tech = {
  id: '5',
  name: 'chakra',
  textColor: 'white',
  bgColor: '#71CACC',
  linkURL: 'https://v2.chakra-ui.com/',
}


////////////////////////////////////////////////////////////////
// TECHLIST BACKEND
////////////////////////////////////////////////////////////////

const techBE_1: Tech = {
  id: '1',
  name: 'MSSQL',
  textColor: 'white',
  bgColor: '#E02225',
  linkURL: 'https://learn.microsoft.com/en-us/sql/',
}

const techBE_2: Tech = {
  id: '2',
  name: 'node',
  textColor: 'black',
  bgColor: '#39B54A',
  linkURL: 'https://nodejs.org/',
}

const techBE_3: Tech = {
  id: '3',
  name: 'express',
  textColor: 'black',
  bgColor: '#F7DF1E',
  linkURL: 'https://expressjs.com/',
}

const techBE_4: Tech = {
  id: '4',
  name: 'mongodb',
  textColor: '#001E2B',
  bgColor: '#00ED64',
  linkURL: 'https://www.mongodb.com/',
}



////////////////////////////////////////////////////////////////
// TECHLIST WEB3
////////////////////////////////////////////////////////////////
const techW3_1: Tech = {
  id: '1',
  name: 'solidity',
  textColor: 'white',
  bgColor: '#666666',
  linkURL: 'https://soliditylang.org/',
}

const techW3_2: Tech = {
  id: '2',
  name: 'kardia-js-sdk',
  textColor: 'white',
  bgColor: '#8F00C0',
  linkURL: 'https://docs.kardiachain.io/js-sdk',
}


export function useTechStack() {

  const techStackListFE: Tech[] = [];
  techStackListFE.push(techFE_1);
  techStackListFE.push(techFE_2);
  techStackListFE.push(techFE_3);
  techStackListFE.push(techFE_4);
  techStackListFE.push(techFE_5);

  const techStackListBE: Tech[] = [];
  techStackListBE.push(techBE_1);
  techStackListBE.push(techBE_2);
  techStackListBE.push(techBE_3);
  techStackListBE.push(techBE_4);

  const techStackListW3: Tech[] = [];
  techStackListW3.push(techW3_1);
  techStackListW3.push(techW3_2);

  return { TECH_STACK_TITLE, TECH_STACK_DESCRIPTION, techStackListFE, techStackListBE, techStackListW3 };
};