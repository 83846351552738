import { Box, Center, HStack, Text, VStack, Image, Spacer, Flex } from "@chakra-ui/react";
import { VscTerminalCmd, VscChromeMinimize, VscChromeMaximize, VscChromeClose, } from "react-icons/vsc";
import { ReactTyped } from "react-typed"

// Assets
// import sectionBG from "../assets//images/section-bgs/section-bg-communication-2000x1125.png"
import imgProfileClear from "../assets/images/about/ramiware-profile-pic-clear.png"
// import imgProfilePixel from "../assets/images/about/ramiware-profile-pic-pixel.png"

// Hooks
import { useTheme } from "../hooks/useTheme";
import { useVersion } from "../hooks/useVersion";

// Components
import SectionHeader from "../components/SectionHeader";




/***********************************************************************************************************
 * COMPONENT START
 ***********************************************************************************************************/
const About = () => {

  let YOE = new Date().getFullYear() - 2006;

  const SECTION_TITLE = "About";
  const SECTION_DESCRIPTION = "Who am I?";

  const SECTION_HDR_TXT = "Hello, I'm Rami Sorikh";
  const SECTION_BODY_DIR = "<br>C:/users/rsorikh>";
  const SECTION_BODY_CMD = SECTION_BODY_DIR + "type about.txt";
  const SECTION_BODY_TXT1 =
    "<br><br><font color='white'>I'm a Toronto-based IT Development Manager by day, and a creative Full-Stack Developer by night. With over "
    + YOE +
    " years of experience, and a passion for creating something out of nothing, there’s never a challenge I don’t enjoy.";
  const SECTION_BODY_TXT2 =
    "<br><br>I create everything and anything that interests me including but not limited to desktop applications, websites, web applications, mobile apps, smart contracts and decentralized applications (dapps).";
  const SECTION_BODY_TXT3 =
    "<br><br>I also happen to enjoy long walks on the beach, countless hours of backgammon, chess, and lifting weights - really, really, heavy weights :)</font>"
    + "<br>" + SECTION_BODY_DIR;


  const SECTION_BODY_LIST = [SECTION_BODY_CMD + SECTION_BODY_TXT1 + SECTION_BODY_TXT2 + SECTION_BODY_TXT3];


  const { colors, fonts, sizes } = useTheme();
  const { SITE_NAME, VERSION, COPYRIGHT } = useVersion();

  /***********************************************************************************************************
   * UI
   ***********************************************************************************************************/
  return (
    <Box id='about'
      // borderTopWidth={'1px'}
      // borderTopColor={colors.DESKTOP_DIVIDER}
      pt={[sizes.SECTION_PTB_S, sizes.SECTION_PTB_S, sizes.SECTION_PTB_L, sizes.SECTION_PTB_L]}
      pb={[sizes.SECTION_PTB_S, sizes.SECTION_PTB_S, sizes.SECTION_PTB_L, sizes.SECTION_PTB_L]}
      bgGradient={colors.ABOUT_GRADIENT_BG}
      color={colors.TXT_BODY1}
      width='100%'
    >


      {/* SECTION TITLE */}
      <SectionHeader title={SECTION_TITLE} description={SECTION_DESCRIPTION} />


      {/* SECTION BODY */}
      <Center>
        <Flex gap={['20px', '20px', '20px', '20px']}
          width={[sizes.SECTION_W_S, sizes.SECTION_W_M, sizes.SECTION_W_L, sizes.SECTION_W_XL]}
          justifyContent={'center'} flexWrap={'wrap'}>

          {/* TERMINAL */}
          <Box boxShadow='dark-lg' rounded={sizes.SECTION_BOX_RADIUS}>
            <Center>
              <VStack spacing='0px'>

                {/* TOOLBAR */}
                <Box height='40px'
                  width={[sizes.TERMINAL_W_S, sizes.TERMINAL_W_M, sizes.TERMINAL_W_L, sizes.TERMINAL_W_XL]}
                  borderWidth={'2px'}
                  borderStyle='solid'
                  borderColor={colors.ACCENT_BODY1}
                  borderTopRadius={sizes.SECTION_BOX_RADIUS}
                  bgColor={colors.ACCENT_BODY2}
                  fontFamily={'cmdBold'} fontStyle={'bold'}
                  fontSize={[fonts.T1_SIZE_S, fonts.T1_SIZE_S, fonts.T1_SIZE_L, fonts.T1_SIZE_L]}
                >
                  <HStack height='100%' pl='10px' pr='10px'>
                    <VscTerminalCmd />
                    <Text pt={['5px', '5px', '2px', '2px']}>{SECTION_HDR_TXT}</Text>
                    <Spacer />
                    <VscChromeMinimize />
                    <VscChromeMaximize />
                    <VscChromeClose />
                  </HStack>
                </Box>

                {/* CONTENT */}
                <Box height={[sizes.TERMINAL_H_S, sizes.TERMINAL_H_M, sizes.TERMINAL_H_L, sizes.TERMINAL_H_XL]}
                  width={[sizes.TERMINAL_W_S, sizes.TERMINAL_W_M, sizes.TERMINAL_W_L, sizes.TERMINAL_W_XL]}
                  p={['10px', '10px', '20px', '20px']}
                  color={colors.ACCENT_BODY1}
                  borderWidth={'2px'}
                  borderStyle='solid'
                  borderTopWidth={'0px'}
                  borderColor={colors.ACCENT_BODY1}
                  borderBottomRadius={sizes.SECTION_BOX_RADIUS}
                  bgColor='black'
                  fontFamily={'cmd'} fontStyle={'normal'}
                  fontSize={[fonts.T2_SIZE_S, fonts.T2_SIZE_S, fonts.T2_SIZE_L, fonts.T2_SIZE_L]}
                  textAlign='left'
                >
                  <Text>{SITE_NAME} [{VERSION}]</Text>
                  <Text>{COPYRIGHT}</Text>
                  <ReactTyped
                    startWhenVisible
                    strings={SECTION_BODY_LIST}
                    typeSpeed={10}
                    backSpeed={100}
                  // loop
                  />
                </Box>

              </VStack>
            </Center>
          </Box>


          {/* PROFILE PIC */}
          <Box boxShadow='dark-lg' rounded={sizes.SECTION_BOX_RADIUS} boxSize={[sizes.PHOTO_BOXSIZE_S, sizes.PHOTO_BOXSIZE_M, sizes.PHOTO_BOXSIZE_L, sizes.PHOTO_BOXSIZE_XL]}>
            <Center>
              <Image src={imgProfileClear}
                boxSize={[sizes.PHOTO_BOXSIZE_S, sizes.PHOTO_BOXSIZE_M, sizes.PHOTO_BOXSIZE_L, sizes.PHOTO_BOXSIZE_XL]}
                borderWidth={'2px'}
                borderStyle='solid'
                borderColor={colors.ACCENT_BODY1}
                borderRadius={sizes.SECTION_BOX_RADIUS}
              ></Image>
            </Center>
          </Box>

        </Flex>
      </Center>


    </Box>
  );
};
export default About;