import { Box, HStack, Link, Image, Spacer, VStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink, IconButton, Drawer, DrawerOverlay, useDisclosure, DrawerContent, DrawerBody, DrawerCloseButton } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { HamburgerIcon } from "@chakra-ui/icons";

// Hooks
import { useTheme } from "../hooks/useTheme";
import { useBusiness } from "../hooks/useBusiness";



/***********************************************************************************************************
 * COMPONENT START
 ***********************************************************************************************************/
const Navigation = () => {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colors, fonts, sizes } = useTheme();
  const { imgRamiwareTxt, card } = useBusiness();

  /***********************************************************************************************************
   * CLASS FUNCTIONS
   ***********************************************************************************************************/

  /////////////////////////////////////////////////
  // Handles opening menu drawer
  /////////////////////////////////////////////////
  function openMenuDrawer() {

    onOpen();
  }

  /***********************************************************************************************************
   * UI
   ***********************************************************************************************************/
  return (

    <Box backgroundColor='rgba(0, 0, 0, 0.0)' zIndex={1}>
      <Box
        as="header"
        position="fixed"
        width='100%'
        backgroundColor='rgba(0, 0, 0, 0.5)'
        backdropFilter="saturate(5%) blur(5px)"
        pt={0}>


        <HStack spacing={['0px', '0px', '10px', '40px']}
          height={[sizes.NAV_HEIGHT_S, sizes.NAV_HEIGHT_S, sizes.NAV_HEIGHT_L, sizes.NAV_HEIGHT_L]}
          width='100%'>

          {/* LOGO TXT */}
          <motion.div
            initial={{ opacity: 1, y: -50, scale: 1 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            transition={{ duration: 1.5, delay: 0.5 }}
          >
            <Link href={card.BUS_WEBSITE}>
              <motion.button
                whileHover={{
                  scale: 1.2,
                  // transition: { duration: 0.2 },
                }}
                whileTap={{ scale: 0.9 }}
              >
                <Image src={imgRamiwareTxt} mt='10px' ml={{ base: '20px', md: '20px' }}
                  width={[sizes.NAV_TEXT_WIDTH_S, sizes.NAV_TEXT_WIDTH_S, sizes.NAV_TEXT_WIDTH_L, sizes.NAV_TEXT_WIDTH_L]}
                />
              </motion.button>
            </Link>
          </motion.div>

          {/* DESKTOP NAV */}
          <Spacer display={{ base: "none", md: "block" }}></Spacer>
          <motion.div
            initial={{ opacity: 1, y: -50, scale: 1 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            transition={{ duration: 1.5, delay: 0.5 }}
          >
            <Box display={{ base: "none", md: "block" }}
              color={colors.NAV_DESKTOP_TXT} fontFamily={'terminal'} fontWeight={"normal"}>
              <Breadcrumb separator=' '
                fontSize={[fonts.NAV_S, fonts.NAV_S, fonts.NAV_L, fonts.NAV_L]}>

                <BreadcrumbItem>
                  <motion.button
                    whileHover={{
                      scale: 1.2,
                      color: colors.ACCENT_BODY1,
                    }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <BreadcrumbLink textDecorationLine={'none'} href='#'>HOME</BreadcrumbLink>
                  </motion.button>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <motion.button
                    whileHover={{
                      scale: 1.2,
                      color: colors.ACCENT_BODY1,
                    }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <BreadcrumbLink textDecorationLine={'none'} href='#about'>ABOUT</BreadcrumbLink>
                  </motion.button>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <motion.button
                    whileHover={{
                      scale: 1.2,
                      color: colors.ACCENT_BODY1,
                    }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <BreadcrumbLink textDecorationLine={'none'} href='#technology'>TECHNOLOGY</BreadcrumbLink>
                  </motion.button>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <motion.button
                    whileHover={{
                      scale: 1.2,
                      color: colors.ACCENT_BODY1,
                    }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <BreadcrumbLink textDecorationLine={'none'} href='#projects'>PROJECTS</BreadcrumbLink>
                  </motion.button>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <motion.button
                    whileHover={{
                      scale: 1.2,
                      color: colors.ACCENT_BODY1,
                    }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <BreadcrumbLink textDecorationLine={'none'} href='#contact'>CONTACT</BreadcrumbLink>
                  </motion.button>
                </BreadcrumbItem>
              </Breadcrumb>
            </Box>
          </motion.div>

          {/* CONTACT (DESKTOP ONLY) */}
          {/* <Spacer display={{ base: "none", md: "block" }}></Spacer> */}
          {/* <motion.div
            initial={{ opacity: 1, x: 150, scale: 1 }}
            animate={{ opacity: 1, x: 0, scale: 1 }}
            transition={{ duration: 1.5, delay: 0.5 }}
          >
            <Box display={{ base: "none", md: "block" }} mr='20px'>
              <motion.button
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.2 },
                }}
                whileTap={{ scale: 0.9 }}
              >
                <Text
                  p='5px'
                  fontSize={[fonts.BUTTON_S, fonts.BUTTON_S, fonts.BUTTON_L, fonts.BUTTON_L]}
                  fontFamily='terminal' fontWeight={'bold'}
                  width={['100px', '100px', '100px', '100px']}
                  bgColor='transparent'
                  color={colors.ACCENT_BODY1}
                  borderWidth='2px'
                  borderStyle='solid'
                  borderColor={colors.ACCENT_BODY1}
                  rounded='md'
                >
                  <Link _hover={{ textDecoration: "none" }} href={'#contact'}>
                    CONTACT
                  </Link>
                </Text>
              </motion.button>
            </Box>
          </motion.div> */}

          {/* MOBILE NAV (HAMBURGER BUTTON ONLY) */}
          <Spacer display={{ base: "block", md: "none" }}></Spacer>
          <motion.div
            // initial={{ opacity: 1, x: 80, scale: 1 }}
            // animate={{ opacity: 1, x: 0, scale: 1 }}
            // transition={{ duration: 1.5, delay: 0.5 }}
            initial={{ opacity: 1, y: -50, scale: 1 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            transition={{ duration: 1.5, delay: 0.5 }}
          >
            <Box display={{ base: "block", md: "none" }} pr='20px'>

              <motion.button
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.2 },
                }}
                whileTap={{ scale: 0.9 }}
              >
                <IconButton
                  bgColor='transparent'
                  color={colors.ACCENT_BODY1}
                  borderColor={colors.ACCENT_BODY1}
                  borderWidth='1px'
                  borderStyle='solid'
                  rounded='md'
                  aria-label='Menu'
                  icon={<HamburgerIcon color={colors.ACCENT_BODY1} />}
                  _dark={{ bg: "transparent" }}
                  _light={{ bg: "transparent" }}
                  _focus={{ backgroundColor: "transparent" }}
                  onClick={openMenuDrawer} />
              </motion.button>

            </Box>
          </motion.div>
        </HStack>


      </Box>


      {/* MOBILE DRAWER */}
      <Drawer onClose={onClose} isOpen={isOpen} placement='top' size={'xs'}>
        <DrawerOverlay />

        <DrawerContent
          bgColor={colors.NAV_MOBILE_BG}
          color={colors.NAV_MOBILE_TXT}
          borderColor={colors.NAV_DIVIDER}
          borderStyle='solid'
          borderWidth={'1px'}
          borderRadius={sizes.SECTION_BOX_RADIUS}
          opacity='0.95'
          width='80%'
        >
          <DrawerCloseButton />

          {/* DRAWER BODY */}
          <DrawerBody
            fontSize={[fonts.NAV_S, fonts.NAV_S, fonts.NAV_L, fonts.NAV_L]}
            fontFamily={'terminal'}
            mt='15px' mb='15px'
          >

            <VStack
              // divider={<StackDivider borderColor={colors.NAV_DIVIDER} />}
              spacing={'10px'}
              align='center'
            >
              <Link href='#'>HOME</Link>
              <Link href='#about'>ABOUT</Link>
              <Link href='#technology'>TECHNOLOGY</Link>
              <Link href='#projects'>PROJECTS</Link>
              <Link href='#contact'>CONTACT</Link>
            </VStack>

          </DrawerBody>

        </DrawerContent>
      </Drawer>

    </Box>
  );
};
export default Navigation;